<template>
  <div class="div_title">
    <context :context=cate />
    <span>
      <InputText v-model="reviewKeyword" v-on:keyup.enter="click('SELECT_BOARD')" placeholder="검색어" size="small" class="input-keyword"/>  
      <Button label="조회" v-on:click="click('SELECT_BOARD')" style="" raised size="small" ></Button>
    </span>
  </div>
  <div class="card" :key="reviewKey" >
    <div id="app-notice">
      <Tabs :value="tabIdx">
        <TabList>
          <Tab value="0">후기</Tab>
          <Tab value="1">작성</Tab>
        </TabList>
        <TabPanels>
          <TabPanel value="0">
            <DataTable ataTable :value="reviewList" dataKey="IDX" :loading="loading" stripedRows selectionMode="single" @rowSelect="onRowSelect">
              <template #empty > <div class="no-result">후기가 없습니다.</div> </template>
              <template #loading> <div class="no-result">후기 검색중...</div> </template>
              <Column header="No" field="IDX" />
              <Column header="TITLE" field="TITLE" />
            </DataTable>
          </TabPanel>
          <TabPanel value="1">
            <write :page="this.api.getCookies('page')" :url="url" :country="country" :imgUrl="imgUrl" :fPrice="fPrice" :idx="idx" :name="name" :orderType="orderType" :key="writeKey" @login="login"/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
    <div v-if="reviewList.length !== 0 && tabIdx !== '1'" >
      <Divider />
      <div class="div_center">
        <label @click="paging('prev')" class="link" >
          이전
        </label>
        <span style="margin:20px">{{ page }}</span>
        <label @click="paging('next')" class="link">
          다음
        </label>
      </div>
    </div>
  </div>
  <div class="ad-container">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
</template>
<script>

// import { useMeta } from 'vue-meta'
import { ref, inject } from 'vue'
import context from '@/components/common/Context.vue'
import ssr from './components/common/ssr'
import write from '@/Write.vue'

export default {
  setup() {
    const loading = ref(false)
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 진행중/후기'
    const keywords = '후기, 리뷰, review, geek9 후기, 직구후기, 직구 후기, 직구리뷰, geek9 리뷰, 사용후기, '
    const description = 'GEEK9를 통해서 거래된 해외 상품들과 사용자 분들의 후기입니다.'
    const geek9Url = ssr.GEEK9_URL + 'review'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    const expandedRows = ref({})
    const cate = ref([
      { label: '게시판', route:'https://contents.geek9.kr' },
      { label: '후기', route: '/review' }
    ])
    return {
      expandedRows, cate, addJsonLd, loading
    }
  },
  components: {
    context, write
  },  
  mounted() {
    this.addJsonLd()
    this.emitter.on('reviewReload', this.reload)
    this.api.setPage('review')
    this.$emit("setMenu", {page:'review'})
    this.click('SELECT_BOARD')
    const page = this.api.getCookies('page')
    const reviewParams = this.$route.params
    if(reviewParams.idx !== undefined) {
      this.tabIdx = "1"
      this.country = reviewParams.country
      this.fPrice = reviewParams.fPrice
      this.url = reviewParams.url
      this.orderType = reviewParams.orderType
      this.name = reviewParams.name
      this.imgUrl = reviewParams.imgUrl
      this.idx = reviewParams.idx
      this.writeKey++
    }
    console.log(page)
    window.scrollTo(0, 0)
  },
  unmounted() {
    this.emitter.off('reviewReload', this.reload)
  },
  data() { 
    return { 
      writeKey:-1,
      tabIdx:'0',
      country:'',
      fPrice:'',
      idx:'',
      imgUrl:'',
      name:'',
      orderType:'',
      url:'',
      noticecKey:-1,
      reviewKeyword:undefined,
      reviewKey:-1,
      page:1,
      reviewList: Array(),
    }
  },
  methods: {
    reload(flag) {
      if(flag === 'dashboard') {
        this.reviewKey++
        this.$emit("setMenu", {page:'reload'})
      }
    },
    login() {
      this.$emit('login')
    },
    async onRowSelect(event) {
      const idx = event.data.IDX
      const link = document.createElement('a')
      link.href = this.api.BACKEND_URL + `/${event.data.TYPE}/` + idx
      link.setAttribute('target', '_blank')
      document.body.appendChild(link)
      link.click()
    },
    async paging(type) {
      if(type === 'prev') {
        if(this.page === 1) {
          alert('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1
      this.loading = true
      let keyword = this.reviewKeyword
      await this.api.axiosGql('SELECT_BOARD', {type:'SELECT_BOARD', contentType: 'review', noticeYn:true, useYn:true, page:this.page, keyword:keyword})
      .then( async (result) => {
        if(result.data.length === 0) {
          this.page -= 1
          alert('마지막 페이지입니다.')
        } else this.reviewList = result.data
      })
      .catch( (err) => {
        console.log(err)
      })
      .finally( ()=> {
        this.loading = false
      })
    },
    async click(type) {
      if(type === 'SELECT_BOARD') {
        this.loading = true
        let keyword = this.reviewKeyword
        this.page = 1
        await this.api.axiosGql(type, {type:type, contentType: 'review', noticeYn:true, page:this.page, keyword:keyword, useYn:true})
        .then( async (result) => {
          if(result.data.length !== 0) this.reviewList = result.data
        })
        .catch( (err) => {
          console.log(err)
        })
        .finally( ()=> {
          this.loading = false
        })
      } else if(type === 'INSERT_BOARD') {
        const insertNotice = this.$refs.insertNotice
        const useYn = insertNotice.$refs.useYn.checked
        const priority = insertNotice.$refs.priority.value
        const title = insertNotice.$refs.title.value
        const content = insertNotice.$refs.content.value
        const contentType = insertNotice.$refs.type.value
        if(title === '' || content === '' || contentType ==='') this.$notify('필수 입력값이 누락되었습니다.')
        else {
          await this.api.axiosGql(type, {type:'INSERT_BOARD', contentType:contentType, useYn:Boolean(useYn), priority:Number(priority), title:title, content:content})
          .then( async (result) => {
            if(result === null || result === undefined) this.$notify('다시 시도해주세요.')
            else {
              this.$notify('등록되었습니다.')
              this.click('SELECT_BOARD')
            }
          }).catch((err) => console.log(err) )
        }
      }
    },
  }
}
</script>
<style scoped>
.datatable-background {
  background-color:#f9f9f9;
}
</style>